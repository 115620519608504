.icon-question:before {
  font-family: 'Flaticon Base';
  content: '\F19E';
  margin-right: 10px;
  color: #13b6cb;
}
.icon-download:before {
  font-family: 'Flaticon Arrows';
  content: '\F131';
  margin-right: 6px;
}
.icon-chevron-down:before {
  font-family: 'Flaticon Arrows';
  content: '\F12B';
  margin-right: 10px;
}
.icon-apple-store:before {
  font-family: 'Flaticon Social';
  content: '\F102';
  margin-right: 10px;
}
.icon-play-store:before {
  font-family: 'Flaticon Social';
  content: '\F10D';
  margin-right: 10px;
}
.icon-decision-fatigue:before {
  font-family: 'Flaticon Base';
  content: '\F18C';
  font-size: 3.5em;
  padding: 0.5em;
  display: block;
  color: #13b6cb;
}
.icon-android:before {
  font-family: 'Flaticon Arrows';
  content: '\F131';
  color: rgba(255, 255, 255, 0.6);
  margin-right: 10px;
}
html,
body,
div,
span,
h1,
h2,
h3,
p,
a,
img,
small,
ul,
li,
label,
footer,
header,
section,
time {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
footer,
header,
section {
  display: block;
}
body {
  line-height: 1;
}
ul {
  list-style: none;
}
body {
  -webkit-text-size-adjust: none;
}
@-ms-viewport {
  width: device-width;
}
body {
  -ms-overflow-style: scrollbar;
}
@media screen and (max-width: 360px) {
  html,
  body {
    min-width: 320px;
  }
}
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  background: #ffffff;
}
body {
  color: #999999;
}
html {
  font-size: 16pt;
}
@media screen and (max-width: 1680px) {
  html {
    font-size: 13pt;
  }
}
@media screen and (max-width: 1280px) {
  html {
    font-size: 12pt;
  }
}
@media screen and (max-width: 360px) {
  html {
    font-size: 11pt;
  }
}
.preload {
  visibility: hidden;
}
body {
  font-family: 'Gotham Pro', Arial, Helvetica, sans-serif;
  font-weight: normal;
  line-height: 1.5em;
}
a {
  -moz-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out,
    border-bottom-color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  text-decoration: none;
}
p {
  margin: 0 0 2em 0;
}
h1,
h2,
h3 {
  font-weight: normal;
  line-height: 1em;
  margin: 0 0 1em 0;
  letter-spacing: -0.0125em;
}
h1 a,
h2 a,
h3 a {
  color: inherit;
  text-decoration: none;
  border-bottom: none;
}
@media screen and (max-width: 980px) {
  h1 br,
  h2 br,
  h3 br {
    display: none;
  }
}
h2 {
  font-size: 1.75em;
  line-height: 1.5em;
}
h3 {
  font-size: 1.5em;
  line-height: 1.5em;
}
@media screen and (max-width: 736px) {
  h2 {
    font-size: 1.25em;
  }
  h3 {
    font-size: 1.2em;
  }
}
a {
  color: inherit;
  border-bottom: dotted 1px #999999;
}
a:hover {
  color: #2a86cb;
}
h1,
h2,
h3 {
  color: #828282;
}
button,
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  border-radius: 6px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  height: 2.85em;
  line-height: 2.95em;
  padding: 0 1.75em;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}
button.icon,
.button.icon {
  padding-left: 1.35em;
}
button.icon:before,
.button.icon:before {
  margin-right: 0.5em;
}
button.small,
.button.small {
  font-size: 0.8em;
}
button:disabled,
.button:disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.25;
}
@media screen and (max-width: 480px) {
  button.icon,
  .button.icon {
    padding: 0;
  }
}
@media screen and (max-width: 480px) and (orientation: portrait) {
  button,
  .button {
    padding: 0;
  }
}
button,
.button {
  background-color: transparent;
  color: #828282 !important;
  box-shadow: inset 0 0 0 2px #e6e6e6;
}
button:hover,
.button:hover {
  background-color: rgba(144, 144, 144, 0.075);
}
button:active,
.button:active {
  background-color: rgba(144, 144, 144, 0.15);
}
button.primary,
.button.primary {
  box-shadow: none;
  background-color: #2a86cb;
  color: #ffffff !important;
}
button.primary:hover,
.button.primary:hover {
  background-color: #2a86cb;
}
button.primary:active,
.button.primary:active {
  background-color: #2a86cb;
}
label {
  display: block;
  font-size: 0.9em;
  font-weight: bold;
  margin: 0 0 1em 0;
}
label {
  color: #828282;
}
.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}
.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-weight: 400;
}
.icon > .label {
  display: none;
}
.icon:before {
  line-height: inherit;
}
.icon {
  color: #e0e0e0;
}
.image {
  border: 0;
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.image img {
  display: block;
}
.image.main {
  display: block;
  margin: 0 0 3em 0;
  width: 100%;
}
.image.main img {
  width: 100%;
}
.image {
  box-shadow: 0 0 60px 1px #e6e6e6;
}
.image.phone {
  box-shadow: none;
}
ul {
  list-style: disc;
  margin: 0 0 2em 0;
  padding-left: 1em;
}
ul li {
  padding-left: 0.5em;
}
ul.alt {
  list-style: none;
  padding-left: 0;
}
ul.alt li {
  border-top-style: solid;
  border-top-width: 1px;
  padding: 0.5em 0;
}
ul.alt li:first-child {
  border-top: 0;
  padding-top: 0;
}
ul.alt > li {
  border-top-color: #e6e6e6;
}
ul.actions {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  cursor: default;
  list-style: none;
  margin-left: -1em;
  padding-left: 0;
}
ul.actions li {
  padding: 0 0 0 1em;
  vertical-align: middle;
}
ul.actions.special {
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  width: 100%;
  margin-left: 0;
}
ul.actions.special li:first-child {
  padding-left: 0;
}
@media screen and (max-width: 480px) {
  ul.actions:not(.fixed) {
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 0;
    width: 100% !important;
  }
  ul.actions:not(.fixed) li {
    -moz-flex-grow: 1;
    -webkit-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    -moz-flex-shrink: 1;
    -webkit-flex-shrink: 1;
    -ms-flex-shrink: 1;
    flex-shrink: 1;
    padding: 1em 0 0 0;
    text-align: center;
    width: 100%;
  }
  ul.actions:not(.fixed) li > * {
    width: 100%;
  }
  ul.actions:not(.fixed) li:first-child {
    padding-top: 0;
  }
  ul.actions:not(.fixed) li button,
  ul.actions:not(.fixed) li .button {
    width: 100%;
  }
  ul.actions:not(.fixed) li button.icon:before,
  ul.actions:not(.fixed) li .button.icon:before {
    margin-left: -0.5em;
  }
}
ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0;
}
ul.icons li {
  display: inline-block;
  padding: 0 1.5em 0 0;
}
ul.icons li:last-child {
  padding-right: 0 !important;
}
ul.icons li .icon:before {
  font-size: 1.75rem;
}
ul.icons.major li {
  padding: 0 2.5em 0 0;
}
ul.icons.major li .icon:before {
  font-size: 2.75rem;
}
@media screen and (max-width: 736px) {
  ul.icons.major li {
    padding: 0 1.25em 0 0;
  }
  ul.icons.major li .icon:before {
    font-size: 1.75rem;
  }
}
ul.icons.labeled li {
  margin: 1em 0;
  padding: 0 2em 0 0;
}
ul.icons.labeled li .icon:before {
  vertical-align: middle;
  font-size: 2.25rem;
  margin-right: 0.5em;
}
ul.icons.labeled li .icon .label {
  display: inline-block;
  vertical-align: middle;
  color: #999999;
}
@media screen and (max-width: 736px) and (orientation: portrait) {
  ul.icons.labeled {
    text-align: left;
    margin-right: auto;
    margin-left: auto;
    display: inline-block;
    white-space: nowrap;
  }
  ul.icons.labeled li {
    display: block;
    padding: 0;
    margin: 1.5em 0 0 0;
  }
  ul.icons.labeled li .icon:before {
    width: 1.5em;
    display: block;
    float: left;
    margin-right: 0;
  }
  ul.icons.labeled li .icon:after {
    content: '';
    display: block;
    clear: both;
  }
  ul.icons.labeled li:first-child {
    margin-top: 0;
  }
}
section.special {
  text-align: center;
}
#download a {
  text-decoration: none;
  border-bottom: none;
}
header p {
  position: relative;
  margin: 0 0 1.5em 0;
}
@media screen and (max-width: 736px) {
  header p br {
    display: none;
  }
}
header h2 + p {
  font-size: 1.25em;
  margin-top: -0.5em;
  line-height: 1.5em;
}
header h3 + p {
  font-size: 1.1em;
  margin-top: -0.25em;
  line-height: 1.5em;
}
header.major {
  margin: 0 0 2.5em 0;
  text-align: center;
}
header.major:after {
  content: '';
  border-bottom: solid 2px #e6e6e6;
  display: block;
  width: 4em;
  margin: 2em auto 0 auto;
}
@media screen and (max-width: 736px) {
  header.major {
    margin: 0 0 2em 0;
  }
  header.major p {
    font-size: 1em;
  }
}
.attention {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: 0 0 2em 0;
}
.attention .image {
  width: 30%;
  margin: 0 3em 0 0;
  display: block;
}
.attention .image img {
  display: block;
  width: 100%;
}
.attention .content {
  width: 70%;
}
.attention .content > :last-child {
  margin-bottom: 0;
}
.attention:nth-child(2n) {
  -moz-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.attention:nth-child(2n) .image {
  margin: 0 0 0 3em;
}
.attention:nth-child(2n) .content {
  text-align: right;
}
@media screen and (max-width: 736px) and (orientation: landscape) {
  .attention .image {
    margin: 0 2em 0 0;
  }
  .attention:nth-child(2n) .image {
    margin: 0 0 0 2em;
  }
}
@media screen and (max-width: 736px) and (orientation: portrait) {
  .attention {
    -moz-flex-direction: column !important;
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .attention .image {
    width: 100%;
    max-width: 60%;
    margin: 0 0 2em 0 !important;
  }
  .attention .content {
    width: 100%;
    text-align: center !important;
  }
}
.wrapper {
  padding: 4.5em 0 2.5em 0;
  background-color: #ffffff;
  border-bottom: solid 2px #eeeeee;
}
.wrapper > .inner {
  margin-left: auto;
  margin-right: auto;
  width: 50em;
}
.wrapper > .inner.alt > * {
  border-top: solid 2px #eeeeee;
  margin-bottom: 0;
  margin-top: 3em;
  padding-top: 3em;
}
.wrapper > .inner.alt > *:first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}
.wrapper.primarystyle {
  background-image: linear-gradient(to right, #13b6cb, #2a86cb);
  color: #d1e5f9;
}
.wrapper.primarystyle button,
.wrapper.primarystyle .button {
  background-color: transparent;
  color: #ffffff !important;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.25);
}
.wrapper.primarystyle button:hover,
.wrapper.primarystyle .button:hover {
  background-color: rgba(255, 255, 255, 0.075);
}
.wrapper.primarystyle button:active,
.wrapper.primarystyle .button:active {
  background-color: rgba(255, 255, 255, 0.2);
}
.wrapper.primarystyle button.primary,
.wrapper.primarystyle .button.primary {
  box-shadow: none;
  background-color: #ffffff;
  color: #2a86cb !important;
}
.wrapper.primarystyle label {
  color: #ffffff;
}
.wrapper.primarystyle ::-webkit-input-placeholder {
  color: #b5d5f5 !important;
}
.wrapper.primarystyle :-moz-placeholder {
  color: #b5d5f5 !important;
}
.wrapper.primarystyle ::-moz-placeholder {
  color: #b5d5f5 !important;
}
.wrapper.primarystyle :-ms-input-placeholder {
  color: #b5d5f5 !important;
}
.wrapper.primarystyle .icon {
  color: #b5d5f5;
}
.wrapper.primarystyle .image {
  box-shadow: 0 0 0 4px #2a86cb, 0 0 0 5px rgba(255, 255, 255, 0.25);
}
.wrapper.primarystyle .image.phone {
  box-shadow: none;
}
.wrapper.primarystyle .image.phone .inner,
.wrapper.primarystyle .image.phone:before,
.wrapper.primarystyle .image.phone:after {
  border-color: rgba(255, 255, 255, 0.25);
}
.wrapper.primarystyle .image.phone .inner:before {
  background: rgba(255, 255, 255, 0.25);
}
.wrapper.primarystyle .image.phone .inner:after {
  border-color: rgba(255, 255, 255, 0.25);
}
.wrapper.primarystyle ul.alt > li {
  border-top-color: rgba(255, 255, 255, 0.25);
}
.wrapper.primarystyle a {
  color: inherit;
  border-bottom-color: rgba(255, 255, 255, 0.5);
  color: #ffffff;
}
.wrapper.primarystyle h1,
.wrapper.primarystyle h2,
.wrapper.primarystyle h3 {
  color: #ffffff;
}
.wrapper.secondarystyle {
  background-color: #fbfbfb;
  color: #999999;
}
.wrapper.secondarystyle button,
.wrapper.secondarystyle .button {
  background-color: transparent;
  color: #828282 !important;
  box-shadow: inset 0 0 0 2px #e6e6e6;
}
.wrapper.secondarystyle button:hover,
.wrapper.secondarystyle .button:hover {
  background-color: rgba(144, 144, 144, 0.075);
}
.wrapper.secondarystyle button:active,
.wrapper.secondarystyle .button:active {
  background-color: rgba(144, 144, 144, 0.15);
}
.wrapper.secondarystyle button.primary,
.wrapper.secondarystyle .button.primary {
  box-shadow: none;
  background-color: #2a86cb;
  color: #ffffff !important;
}
.wrapper.secondarystyle button.primary:hover,
.wrapper.secondarystyle .button.primary:hover {
  background-color: #5ca3e8;
}
.wrapper.secondarystyle button.primary:active,
.wrapper.secondarystyle .button.primary:active {
  background-color: #3089e2;
}
.wrapper.secondarystyle label {
  color: #828282;
}
.wrapper.secondarystyle ::-webkit-input-placeholder {
  color: #e0e0e0 !important;
}
.wrapper.secondarystyle :-moz-placeholder {
  color: #e0e0e0 !important;
}
.wrapper.secondarystyle ::-moz-placeholder {
  color: #e0e0e0 !important;
}
.wrapper.secondarystyle :-ms-input-placeholder {
  color: #e0e0e0 !important;
}
.wrapper.secondarystyle .icon {
  color: #e0e0e0;
}
.wrapper.secondarystyle .image {
  box-shadow: 0 0 0 4px #fbfbfb, 0 0 0 5px #e6e6e6;
}
.wrapper.secondarystyle .image.phone {
  box-shadow: none;
}
.wrapper.secondarystyle .image.phone .inner,
.wrapper.secondarystyle .image.phone:before,
.wrapper.secondarystyle .image.phone:after {
  border-color: #e6e6e6;
}
.wrapper.secondarystyle .image.phone .inner:before {
  background: #e6e6e6;
}
.wrapper.secondarystyle .image.phone .inner:after {
  border-color: #e6e6e6;
}
.wrapper.secondarystyle ul.alt > li {
  border-top-color: #e6e6e6;
}
.wrapper.secondarystyle a {
  color: inherit;
  border-bottom-color: #d6d6d6;
}
.wrapper.secondarystyle a:hover {
  color: #2a86cb;
}
.wrapper.secondarystyle h1,
.wrapper.secondarystyle h2,
.wrapper.secondarystyle h3 {
  color: #828282;
}
@media screen and (max-width: 980px) {
  .wrapper {
    padding: 4em 4em 2em 4em;
  }
  .wrapper > .inner {
    width: 100%;
  }
}
@media screen and (max-width: 736px) and (orientation: landscape) {
  .wrapper {
    padding: 3em 3em 1em 3em;
  }
}
@media screen and (max-width: 736px) and (orientation: portrait) {
  .wrapper {
    padding: 3em 1.5em 1em 1.5em;
  }
}
@media screen and (max-width: 480px) and (orientation: landscape) {
  .wrapper {
    padding: 2em 1.5em 0.1em 1.5em;
  }
}
@media screen and (max-width: 480px) and (orientation: portrait) {
  .wrapper {
    padding: 2em 1em 0.1em 1em;
  }
}
#header {
  background-image: linear-gradient(to right, #13b6cb, #2a86cb);
  color: #d1e5f9;
  padding: 4.5em 0 2.5em 0;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  background-position: top left, center center;
  background-attachment: fixed, fixed;
  background-size: auto, cover;
  height: 100vh;
  min-height: 35em;
}
#header button,
#header .button {
  background-color: transparent;
  color: #ffffff !important;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.25);
}
#header button:hover,
#header .button:hover {
  background-color: rgba(255, 255, 255, 0.075);
}
#header button:active,
#header .button:active {
  background-color: rgba(255, 255, 255, 0.2);
}
#header button.primary,
#header .button.primary {
  box-shadow: none;
  background-color: #ffffff;
  color: #2a86cb !important;
}
#header label {
  color: #ffffff;
}
#header ::-webkit-input-placeholder {
  color: #b5d5f5 !important;
}
#header :-moz-placeholder {
  color: #b5d5f5 !important;
}
#header ::-moz-placeholder {
  color: #b5d5f5 !important;
}
#header :-ms-input-placeholder {
  color: #b5d5f5 !important;
}
#header .icon {
  color: #b5d5f5;
}
#header .image {
  box-shadow: 0 0 0 4px #2a86cb, 0 0 0 5px rgba(255, 255, 255, 0.25);
}
#header .image.phone {
  box-shadow: none;
}
#header .image.phone .inner,
#header .image.phone:before,
#header .image.phone:after {
  border-color: rgba(255, 255, 255, 0.25);
}
#header .image.phone .inner:before {
  background: rgba(255, 255, 255, 0.25);
}
#header .image.phone .inner:after {
  border-color: rgba(255, 255, 255, 0.25);
}
#header ul.alt > li {
  border-top-color: rgba(255, 255, 255, 0.25);
}
#header a {
  color: inherit;
  border-bottom-color: rgba(255, 255, 255, 0.5);
  color: #ffffff;
}
#header h1,
#header h2,
#header h3 {
  color: #ffffff;
}
#header h1 {
  font-size: 3.25em;
  font-weight: bold;
  margin-bottom: 0.5em;
}
#header p {
  font-size: 1.5em;
  line-height: 1.35em;
}
#header p br {
  display: inline;
}
#header .content {
  -moz-transition: opacity 0.5s ease, -moz-transform 1s ease;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 1s ease;
  -ms-transition: opacity 0.5s ease, -ms-transform 1s ease;
  transition: opacity 0.5s ease, transform 1s ease;
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  text-align: left;
  margin: -3.5em 4em 0 0;
}
#header .content > :last-child {
  margin-bottom: 0;
}
#header .content .actions {
  -moz-justify-content: -moz-flex-start;
  -webkit-justify-content: -webkit-flex-start;
  -ms-justify-content: -ms-flex-start;
  justify-content: flex-start;
}
#header .image {
  -moz-transition: opacity 1s ease, -moz-transform 1s ease;
  -webkit-transition: opacity 1s ease, -webkit-transform 1s ease;
  -ms-transition: opacity 1s ease, -ms-transform 1s ease;
  transition: opacity 1s ease, transform 1s ease;
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}
#header .image img {
  -moz-transition: opacity 0.75s ease;
  -webkit-transition: opacity 0.75s ease;
  -ms-transition: opacity 0.75s ease;
  transition: opacity 0.75s ease;
  -moz-transition-delay: 0.75s;
  -webkit-transition-delay: 0.75s;
  -ms-transition-delay: 0.75s;
  transition-delay: 0.75s;
  opacity: 1;
}
@media screen and (max-width: 1280px) {
}
@media screen and (max-width: 980px) {
  #header {
    height: auto;
    min-height: 0;
  }
  #header .content {
    margin: -2em 4em 0 0;
  }
  #header .content .actions {
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
  }
}
@media screen and (max-width: 980px) and (orientation: portrait) {
  #header {
    padding: 6em 3em 4em 3em;
    -moz-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  #header .content {
    display: block;
    width: 100%;
    text-align: center;
    margin: 0 0 2em 0;
  }
  #header .image {
    overflow: hidden;
  }
}
@media screen and (max-width: 736px) {
  #header h1 {
    font-size: 2em;
  }
  #header p {
    font-size: 1em;
    line-height: inherit;
  }
}
@media screen and (max-width: 736px) and (orientation: landscape) {
  #header {
    padding: 2em 2em 0.1em 2em;
  }
  #header .content {
    margin: 0 2em 2em 0;
  }
  #header .image {
    font-size: 0.8em;
    width: 15em;
    max-width: 20vw;
  }
  #header .image .inner,
  #header .image:before,
  #header .image:after {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 736px) and (orientation: portrait) {
  #header {
    padding: 3em 1.5em 1em 1.5em;
  }
  #header .image {
    font-size: 0.6em;
    max-width: 60vw;
  }
}
@media screen and (max-width: 480px) and (orientation: landscape) {
  #header {
    padding: 2em 1.5em 0.1em 1.5em;
  }
  #header .image {
    font-size: 0.7em;
  }
  #header .actions {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 480px) and (orientation: portrait) {
  #header {
    padding: 2em 1em 0.1em 1em;
  }
}
#main {
  padding: 4em 0 2em 0;
}
@media screen and (max-width: 736px) {
  #main {
    padding: 3em 0 1em 0;
  }
}
#footer {
  background-color: #f7f7f7;
  color: #999999;
  padding: 2em 0;
  color: #b0b0b0;
  text-align: center;
}
#footer p {
  margin: 0;
  padding: 0;
}
#footer a {
  color: inherit;
  border-bottom-color: #c6c6c6;
}
#footer a:hover {
  color: #2a86cb;
}
#footer h1,
#footer h2,
#footer h3 {
  color: #828282;
}
#footer a {
  color: inherit;
}
#footer .copyright {
  font-size: 0.8em;
}
@media screen and (max-width: 980px) {
  #footer {
    padding: 2em;
  }
}
@media screen and (max-width: 736px) and (orientation: landscape) {
  #footer {
    padding: 1em;
  }
}
@media screen and (max-width: 736px) and (orientation: portrait) {
  #footer {
    padding: 1em;
  }
}
